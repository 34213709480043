import React, { Fragment } from 'react'
import ReactAudioPlayer from 'react-audio-player'

const Inicio = () => {
    
    return (
    	<Fragment>

    		<ReactAudioPlayer
				src="/assets/audio/hbd.mp3"
				className="song"
				autoPlay={false}
				controls={false}
			/>

    		<div className="balloons text-center" id="b1">
		    	<h2 className="color_1"></h2>
		    </div>
		    <div className="balloons text-center" id="b2">
		    	<h2 className="color_2">K</h2>
		    </div>
			<div className="balloons text-center" id="b3">
		    	<h2 className="color_3">A</h2>
		    </div>
			<div className="balloons text-center" id="b4">
		    	<h2 className="color_4">R</h2>
		    </div>
			<div className="balloons text-center" id="b5">
		    	<h2 className="color_5">E</h2>
		    </div>
		    <div className="balloons text-center" id="b6">
		    	<h2 className="color_6">N</h2>
		    </div>
		    <div className="balloons text-center" id="b7">
		    	<h2 className="color_7"></h2>
		    </div>

		    <img src="assets/images/Balloon-Border.png" width="100%" className="balloon-border" />

		    <div className="container">
				<div className="row">
					<div className="col-md-2 col-xs-2 bulb-holder">
						<div className="bulb" id="bulb_yellow"></div>
					</div>
					<div className="col-md-2 col-xs-2 bulb-holder">
						<div className="bulb" id="bulb_red"></div>
					</div>
					<div className="col-md-2 col-xs-2 bulb-holder">
						<div className="bulb" id="bulb_blue"></div>
					</div>
					<div className="col-md-2 col-xs-2 bulb-holder">
						<div className="bulb" id="bulb_green"></div>
					</div>
					<div className="col-md-2 col-xs-2 bulb-holder">
						<div className="bulb" id="bulb_pink"></div>
					</div>
					<div className="col-md-2 col-xs-2 bulb-holder">
						<div className="bulb" id="bulb_orange"></div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 text-center">
						<img src="assets/images/banner.png" className="bannar" />
					</div>
				</div>
				<div className="row cake-cover">
					<div className="col-md-12 texr-center">
						<div className="cake">
						  <div className="velas">
						    <div className="fuego"></div>
						    <div className="fuego"></div>
						    <div className="fuego"></div>
						    <div className="fuego"></div>
						    <div className="fuego"></div>
						  </div>
						  <div className="cobertura"></div>
						  <div className="bizcocho"></div>
						</div>
					</div>
				</div>
				<div className="row message">
					<div className="col-md-12">
						<p>En tu cumpleaños</p>
						<p>deseo</p>
						<p>como siempre</p>
						<p>que seas muy feliz.</p>
						<p>Te quiero más que a nada</p>
						<p>Muchas felicidades 😘</p>
					</div>
				</div>
				<div className="navbar navbar-fixed-bottom">
					<div className="row">
						<div className="col-md-6 text-center col-md-offset-3">
							<button className="btn btn-primary" id="turn_on">Enciende las luces</button>
							<button className="btn btn-primary" id="play">Dale play</button>
							<button className="btn btn-primary" id="bannar_coming">Decoremos un poco</button>
							<button className="btn btn-primary" id="balloons_flying">¡A volar!</button>
							<button className="btn btn-primary" id="cake_fadein">El pastel mas rico</button>
							<button className="btn btn-primary" id="light_candle">Enciende la velita</button>
							<button className="btn btn-primary" id="wish_message">Feliz cumpleaños</button>
							<button className="btn btn-primary" id="story">¡Sorpresa!</button>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
    )
}

export default Inicio;